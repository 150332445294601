// src/oktaConfig.js
import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
  issuer: 'https://dev-60443380.okta.com/oauth2/default', // Replace with your Okta domain
  clientId: '0oam03s1wrPsvZFow5d7',                       // Replace with your client ID
  redirectUri: "http://localhost:3001/pages/callback", // Set redirect URI,
  scopes: ['openid', 'profile', 'email'],
  pkce:true,
  disableHttpsCheck:true,
});

export default oktaAuth;
