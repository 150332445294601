import React, { useState, useEffect } from "react";
import { Card } from "./../../components/card/card.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../components/sidebar/sidebar.jsx";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

function AddSubscription() {

  const [show, setShow] = useState(false);
  const [editshows, setEditShows] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [status, setStatus] = useState(true); // Default to true (Active)
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    description: "",
    summarizePoints: [""], // Initialize with one summarize point
  status:true
  });
  const [errors, setErrors] = useState({});
  const location = useLocation();



  // Show modal handlers
  const handleClose = () => setShow(false);
  const handleShow = (subscription) => {
    setCurrentSubscription(subscription);
    setShow(true);
  };

  const handleCloses = () => setEditShows(false);
  const handleShows = (subscription) => {
    setCurrentSubscription(subscription);
    setFormData(subscription);
    setEditShows(true);
  };

  const handleAddClose = () => setAddShow(false);
  const handleAddShow = () => {
    setFormData({
      name: "",
      price: "",
      description: "",
      summarizePoints: [""], // Initialize with one summarize point
      status:true
    });
    setAddShow(true);
  };

  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = (subscription) => {
    setCurrentSubscription(subscription);
    setDeleteShow(true);
  };

  // Fetch subscriptions
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getSubscriptions`);
      setSubscriptions(response.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  // Input change handler
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Dynamic points handlers
  const handleAddPoint = () => {
    setFormData((prevData) => ({
      ...prevData,
      summarizePoints: [...prevData.summarizePoints, ""],
    }));
  };

  const handleRemovePoint = (index) => {
    if (formData?.summarizePoints?.length > 1) {
      setFormData((prevData) => ({
        ...prevData,
        summarizePoints: prevData.summarizePoints.filter((_, i) => i !== index),
      }));
    }
  };

  const handlePointChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const newPoints = [...prevData.summarizePoints];
      newPoints[index] = value;
      return { ...prevData, summarizePoints: newPoints };
    });
  };

  // Form validation
  const validateForm = () => {
    let formErrors = {};
  
    if (!formData.name) formErrors.name = "Name is required.";
    if (!formData.price || isNaN(formData.price)) formErrors.price = "Valid price is required.";
    if (!formData.description) formErrors.description = "Description is required.";
  
    // Check if summarize points array is empty or contains empty values
    if (!formData?.summarizePoints?.length) {
      formErrors.summarizePoints = "At least one summarize point is required.";
    } else {
      formData.summarizePoints.forEach((point, index) => {
        if (!point.trim()) {
          formErrors[`summarizePoint${index}`] = `Summarize point ${index + 1} cannot be empty.`;
        }
      });
    }
  
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  

  // API Calls
  const handleAddSubscription = async () => {
    if (!validateForm()) return;

    try {
      const token = sessionStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/createSubscription`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      fetchSubscriptions();
      handleAddClose();
      toast.success("Subscription added successfully!");
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Failed to add subscription.");
    }
  };

  const handleEditSubscription = async () => {
    if (!validateForm()) return;

    try {
      const token = sessionStorage.getItem("token");
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/editSubscription/${currentSubscription.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      fetchSubscriptions();
      handleCloses();
      toast.success("Subscription updated successfully!");
    } catch (error) {
      console.error("Error editing subscription:", error);
      toast.error("Failed to update subscription.");
    }
  };

  const handleDeleteSubscription = async () => {
    try {
      const token = sessionStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/deleteSubscription/${currentSubscription.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      fetchSubscriptions();
      handleDeleteClose();
      toast.success("Subscription deleted successfully!");
    } catch (error) {
      console.error("Error deleting subscription:", error);
      toast.error("Failed to delete subscription.");
    }
  };

  const userPermissions = useSelector((state) => state.getUser.user?.UserPermissions || []);



  const currentPermission = userPermissions.find(
    (perm) => `/pages/${perm.routeName}` === location.pathname
  );

  const hasAnyPermission = currentPermission?.UserPermissionsRelation?.canRead ||
  currentPermission?.UserPermissionsRelation?.canEdit ||
  currentPermission?.UserPermissionsRelation?.canAdd ||
  currentPermission?.UserPermissionsRelation?.canDelete;

  return (
    <div>
      <Sidebar />
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">SUBSCRIPTION</li>
          </ul>
          <h1 className="page-header mb-0">Subscription</h1>
        </div>
        {currentPermission?.UserPermissionsRelation?.canAdd &&
        <div className="ms-auto">
          <a href="#/" className="btn btn-outline-theme" onClick={handleAddShow}>
            <i className="fa fa-plus-circle fa-fw me-1"></i> ADD
          </a>
        </div>}
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">Name</th>
                  <th className="border-top-0 pt-0 pb-2">Price</th>
                  <th className="border-top-0 pt-0 pb-2">Description</th>
                  {hasAnyPermission && ( <th className="border-top-0 pt-0 pb-2">Action</th>)}
                </tr>
              </thead>
              <tbody>
                {subscriptions?.map((subscription) => (
                  <tr key={subscription.id}>
                    <td className="align-middle">{subscription.name}</td>
                    <td className="py-1 align-middle">
                      <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        ${parseFloat(subscription.price).toFixed(2).replace(/\.00$/, "")}
                      </span>
                    </td>
                    <td className="align-middle">{subscription.description}</td>
                    <td>
                      <div className="action-btn-group d-flex">
                      {currentPermission?.UserPermissionsRelation?.canEdit &&
                        <button
                          className="btn btn-edit border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                          onClick={() => handleShows(subscription)}
                        >
                          Edit
                        </button>}
                        {currentPermission?.UserPermissionsRelation?.canRead &&
                        <button
                          className="btn btn-show border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center mx-2"
                          onClick={() => handleShow(subscription)}
                        >
                          Show
                        </button>}
                        {currentPermission?.UserPermissionsRelation?.canDelete &&
                        <button
                          className="btn btn-delete border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                          onClick={() => handleDeleteShow(subscription)}
                        >
                          Delete
                        </button>}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>

      {/* Add Subscription Modal */}
      <Modal show={addShow} onHide={handleAddClose} className="right-side-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <label>Name<span className="text-danger">*</span></label>
              <input
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && <span className="text-danger">{errors.name}</span>}
            </div>
            <div className="form-group">
              <label>Price<span className="text-danger">*</span></label>
              <input
                name="price"
                className="form-control"
                value={formData.price}
                onChange={handleInputChange}
              />
              {errors.price && <span className="text-danger">{errors.price}</span>}
            </div>
            <div className="form-group">
              <label>Description<span className="text-danger">*</span></label>
              <textarea
                name="description"
                className="form-control"
                value={formData.description}
                onChange={handleInputChange}
              ></textarea>
              {errors.description && <span className="text-danger">{errors.description}</span>}
            </div>
            <div className="form-group">
              <label>Summarize Points<span className="text-danger">*</span></label>
              {formData?.summarizePoints?.map((point, index) => (
                <div key={index} className="d-flex mb-2">
                  <input
                    type="text"
                    className="form-control me-2"
                    value={point}
                    onChange={(e) => handlePointChange(e, index)}
                    placeholder={`Point ${index + 1}`}
                  />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemovePoint(index)}
                    disabled={formData?.summarizePoints?.length === 1}
                  >
                    Remove
                  </button>
                  {errors[`summarizePoint${index}`] && <span className="text-danger ms-2">{errors[`summarizePoint${index}`]}</span>}

                </div>
              ))}
              <button type="button" className="btn btn-primary mt-2" onClick={handleAddPoint}>
                Add Point
              </button>
            </div>

            <div className="form-group mt-3">
  <label>Status</label>
  <div className="form-check form-switch">
    <input
      type="checkbox"
      className="form-check-input"
      id="addDocumentStatus"
      checked={formData.status} // Bind this to a new state variable `status`
      onChange={() =>    setFormData({
        ...formData,
        status: !formData.status,
      })}
    />
    <label className="form-check-label" htmlFor="addDocumentStatus">
      {status ? "Active" : "Inactive"}
    </label>
  </div>
</div>
            <div className="form-button">
              <button className="btn btn-outline-theme" onClick={handleAddSubscription}>
                ADD
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit Subscription Modal */}
      <Modal show={editshows} onHide={handleCloses} className="right-side-modal">
        <Modal.Header closeButton>
          <Modal.Title>Edit Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <label>Name<span className="text-danger">*</span></label>
              <input
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && <span className="text-danger">{errors.name}</span>}
            </div>
            <div className="form-group">
              <label>Price<span className="text-danger">*</span></label>
              <input
                name="price"
                className="form-control"
                value={formData.price}
                onChange={handleInputChange}
              />
              {errors.price && <span className="text-danger">{errors.price}</span>}
            </div>
            <div className="form-group">
              <label>Description<span className="text-danger">*</span></label>
              <textarea
                name="description"
                className="form-control"
                value={formData.description}
                onChange={handleInputChange}
              ></textarea>
              {errors.description && <span className="text-danger">{errors.description}</span>}
            </div>
            <div className="form-group">
              <label>Summarize Points<span className="text-danger">*</span></label>
              {formData?.summarizePoints?.map((point, index) => (
                <div key={index} className="d-flex mb-2">
                  <input
                    type="text"
                    className="form-control me-2"
                    value={point}
                    onChange={(e) => handlePointChange(e, index)}
                    placeholder={`Point ${index + 1}`}
                  />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemovePoint(index)}
                    disabled={formData?.summarizePoints?.length === 1}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button type="button" className="btn btn-primary mt-2" onClick={handleAddPoint}>
                Add Point
              </button>
            </div>
            <div className="form-group mt-3">
  <label>Status</label>
  <div className="form-check form-switch">
    <input
      type="checkbox"
      className="form-check-input"
      id="addDocumentStatus"
      checked={formData.status} // Bind this to a new state variable `status`
      onChange={() =>    setFormData({
        ...formData,
        status: !formData.status,
      })}
    />
    <label className="form-check-label" htmlFor="addDocumentStatus">
      {status ? "Active" : "Inactive"}
    </label>
  </div>
</div>
            <div className="form-button">
              <button className="btn btn-outline-theme" onClick={handleEditSubscription}>
                Save Changes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Show Subscription Modal */}
      <Modal show={show} onHide={handleClose} className="right-side-modal">
        <Modal.Header closeButton>
          <Modal.Title>Show Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <label>Name</label>

              <p>{currentSubscription?.name || ""}</p>
             
            </div>
            <div className="form-group">
              <label>Price</label>
              <p>{currentSubscription?.price || ""}</p>
             
            </div>
            <div className="form-group">
              <label>Description</label>
              <p>{currentSubscription?.description || ""}</p>
             
            </div>
            <div className="form-group">
              <label>Summarize Points</label>
              {currentSubscription?.summarizePoints?.map((point, index) => (
                <div key={index} className="mb-2">
                 
                  <p>{point}</p>
                </div>
              )) || <p>No summarize points available.</p>}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Subscription Modal */}
      <Modal show={deleteShow} onHide={handleDeleteClose} className="delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this subscription?</p>
          <div className="form-button">
            <button className="btn btn-outline-theme" onClick={handleDeleteSubscription}>
              Yes, Delete
            </button>
            <button className="btn btn-outline-danger ms-2" onClick={handleDeleteClose}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </div>
  );
}

export default AddSubscription;
