import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AppSettings } from "./../../config/app-settings";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons"; // Example for using FontAwesome icons

function EnableMFA() {
  const userId = new URLSearchParams(window.location.search).get("userid");
  const navigate = useNavigate();
  const context = useContext(AppSettings);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [backupCodes, setBackupCodes] = useState([]);
  const [totpCode, setTotpCode] = useState("");
  const [backupCode, setBackupCode] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [step, setStep] = useState("checkMFA"); // Possible values: 'checkMFA', 'setup', 'verifyTOTP', 'verifyBackupCode'

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    checkQrCodeStatus()
  

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };
  }, []); // Runs only on component mount


  const checkQrCodeStatus = async () => {
    try {
        setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/checkQrCodeStatus`, { userId });
      if(response.data.qrCodeGeneratedStatus){
        setStep("verifyTOTP");
        setLoading(false);
      }else{
        handleEnableMFA();
      }
      
     
    } catch (error) {
      console.error("Error enabling MFA:", error);
    }
  };


  const handleEnableMFA = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/enable-mfa`, { userId });
      setQrCodeUrl(response.data.qrCodeUrl);
      setBackupCodes(response.data.backupCodes);
      setStep("setup");
    } catch (error) {
      console.error("Error enabling MFA:", error);
    }finally{
        setLoading(false)
    }
  };

  const handleVerifyTOTP = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/verifyTotp`, { userId, code: totpCode });
      if(response.data.success){
        navigate(`/pages/callback`);
      }
    } catch (error) {
      console.error("Error verifying TOTP:", error);
    }
  };

  const handleVerifyBackupCode = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/verifyBackupCode`, { userId, backupCode });
      if(response.data.success){
        navigate(`/pages/callback`);
      }
    } catch (error) {
      console.error("Error verifying backup code:", error);
    }
  };

  return (
    <div className="enable-mfa"
    
    >
      <div className="brand text-center">
        <h1>
          <FontAwesomeIcon icon={faKey} /> Enable MFA
        </h1>
      </div>

      
      {loading && (
  <div
    style={{
      position: "fixed", // Ensures it stays in the viewport
      top: "50%", // Vertically centers it
      left: "50%", // Horizontally centers it
      transform: "translate(-50%, -50%)", // Adjusts for the spinner's size
      zIndex: 1000, // Keeps it on top of other elements
    }}
  >
    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)}



      <div className="enable-mfa-content">
        {step === "setup" && qrCodeUrl && !loading && (
          <div className="text-center">
            <h3>Scan the QR Code to Set Up MFA</h3>
            <div>
              <img src={qrCodeUrl} alt="Scan QR Code" style={{ maxWidth: "200px", marginBottom: "1rem" }} />
            </div>
            <h4>Backup Codes</h4>
            <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
              {backupCodes?.map((code) => (
                <li key={code} style={{ marginBottom: "0.5rem" }}>
                  <code>{code}</code>
                </li>
              ))}
            </ul>
            <button className="btn btn-outline-theme btn-lg mt-3" onClick={() => setStep("verifyTOTP")}>
              Next
            </button>
          </div>
        )}

        {step === "verifyTOTP" && !loading && (
          <div className="text-center">
            <h3>Verify TOTP Code</h3>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5 mb-3"
              placeholder="Enter TOTP Code"
              value={totpCode}
              onChange={(e) => setTotpCode(e.target.value)}
            />
            <button className="btn btn-outline-theme btn-lg" onClick={handleVerifyTOTP}>
              Verify TOTP
            </button>
            <p
              style={{
                fontSize: "small",
                cursor: "pointer",
                color: "blue",
                marginTop: "1rem",
              }}
              onClick={() => setStep("verifyBackupCode")}
            >
              Verify using Backup Code?
            </p>
          </div>
        )}

        {step === "verifyBackupCode" && (
          <div className="text-center">
            <h3>Enter Backup Code</h3>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5 mb-3"
              placeholder="Enter Backup Code"
              value={backupCode}
              onChange={(e) => setBackupCode(e.target.value)}
            />
            <button className="btn btn-outline-theme btn-lg" onClick={handleVerifyBackupCode}>
              Verify Backup Code
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EnableMFA;
