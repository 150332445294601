import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons"; // Example for using FontAwesome icons
import { useSelector, useDispatch } from 'react-redux';

function ProfileEnableMFA() {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [backupCodes, setBackupCodes] = useState([]);
  const [qrCodeActive, setQrCodeActive] = useState(""); // State for toggle switch
  const [loading, setLoading] = useState(false); // Loading state
  const user = useSelector((state) => state.getUser.user);

  useEffect(() => {
    
    fetchInitialMFAStatus();
    handleEnableMFA();

  }, []); // Runs only on component mount

  const fetchInitialMFAStatus=async()=>{

    setLoading(true);
    var response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/check-mfa?email=${user.email}`
    );

   
      setQrCodeActive(response.data.isEnabled)
    
  }

  

  const handleEnableMFA = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/enable-mfa`, { userId: user.id });
      setQrCodeUrl(response.data.qrCodeUrl);
      setBackupCodes(response.data.backupCodes);
    } catch (error) {
      console.error("Error enabling MFA:", error);
    }finally {
      setLoading(false);
    }
  };

  const handleToggle = async () => {
    try {
      setLoading(true);
      setQrCodeActive(!qrCodeActive);
      // Call API for toggle state
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/toggleMfaStatus`,
        { userId: user.id }
      );
      if (response.data.statusFinal) {
        setQrCodeActive(!qrCodeActive);
        handleEnableMFA();
      } else {
        setQrCodeActive(!qrCodeActive);
        setQrCodeUrl("")
        setBackupCodes([])
      }
    } catch (error) {
      console.error("Error toggling MFA:", error);
    }finally {
      setLoading(false);
    }
  };

  return (
    <div className="enable-mfa">
      <div className="brand text-center">
        <h1>
          <FontAwesomeIcon icon={faKey} /> Enable MFA
        </h1>
      </div>
      <div className="enable-mfa-content">
        <div className="text-center">
          <h3>Scan the QR Code to Set Up MFA</h3>

          {/* Custom Toggle Switch */}
          <div
            className="toggle-switch"
            onClick={handleToggle}
            style={{
              display: "inline-block",
              cursor: "pointer",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "25px",
                backgroundColor: qrCodeActive ? "blue" : "gray",
                borderRadius: "15px",
                position: "relative",
                transition: "background-color 0.3s",
              }}
            >
              <div
                style={{
                  width: "23px",
                  height: "23px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "1px",
                  left: qrCodeActive ? "26px" : "1px",
                  transition: "left 0.3s",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
              ></div>
            </div>
          </div>

          {loading && (
            <div style={{ marginBottom: "1rem" }}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {/* QR Code */}
          {qrCodeActive && !loading && qrCodeUrl && (
            <>
             <div>
              <img src={qrCodeUrl} alt="Scan QR Code" style={{ maxWidth: "200px", marginBottom: "1rem" }} />
            </div>
             <h4>Backup Codes</h4>
             <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
               {backupCodes?.map((code) => (
                 <li key={code} style={{ marginBottom: "0.5rem" }}>
                   <code>{code}</code>
                 </li>
               ))}
             </ul>
            </>
           
          )}

          {/* Backup Codes */}
         
        </div>
      </div>
    </div>
  );
}

export default ProfileEnableMFA;
