import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { getUserSuccess, getUserFailure } from "../../redux/slices/getUser.js";
import { loginSuccess, loginFailure,firstTimeLoginStatusChange } from "../../redux/slices/authSlice.js";
import { loginUser } from "../../apis/userApi.js";

function LoginCallback() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.getUser.mfaData);

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);

    // const referrer = document.referrer;
    // const oktaDomain = "okta"; // Replace with your specific Okta domain if needed
    // console.log("referrer",referrer)

    // if (!referrer || !referrer.includes(oktaDomain)) {
    //   // Redirect to login page if referrer is not Okta
    //   console.log("referrer",referrer)
    //   // navigate("/pages/login");
    //   return;
    // }

    const fetchUserData = async (userData) => {
      let response;
      try {
        if (
          userData?.userType === "superAdmin" ||
          userData?.userType === "subAdmin"
        ) {
          response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/getAdminUser`,
            {
              headers: { Authorization: `Bearer ${userData.token}` },
            }
          );
          navigate(`/pages/${response.data.UserPermissions[0]?.routeName}`);
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/getUser`,
            {
              headers: { Authorization: `Bearer ${userData.token}` },
            }
          );
           navigate("/dashboard");
        }
        dispatch(getUserSuccess(response.data));
      } catch (error) {
        dispatch(getUserFailure(error.message));
      }
    };


   
    const performLoginAndFetch = async () => {
      try {
        // Replace these variables with the appropriate values
        const email = `${process.env.REACT_APP_OKTA_EMAIL}`; // Placeholder for email
        const password = user?.password; // Placeholder for password
        const rememberMe = true; // Example boolean

        // Call loginUser function (you need to define this function in your application)
        const loginResponse = await loginUser(email, password, rememberMe);

        if(loginResponse?.firstTimeLogin){
          dispatch(firstTimeLoginStatusChange(true));
          setTimeout(() => {
            dispatch(firstTimeLoginStatusChange(false));
          }, 5000);
  
        }
  
        if (loginResponse.token) {
          sessionStorage.setItem("token", loginResponse.token);
          dispatch(loginSuccess(loginResponse));
       
  
  
          await fetchUserData(loginResponse);
          
  
        
        } else {
     
          dispatch(loginFailure(loginResponse.message || "Login failed"));
    
        }

        // Now fetch user data based on the login response
     


        // fetchUserData(loginResponse.data); // Assuming loginResponse.data contains user data
      } catch (error) {
        console.error("Error during login and fetch:", error);
      }
    };

    performLoginAndFetch();

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };
  }, [navigate]);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
   
  };

  const spinnerStyle = {
    marginBottom: "20px",
  };

  const loadingTextStyle = {
    fontSize: "1.2rem",
    color: "#007bff", // Primary blue color
    animation: "fade-in 1.5s infinite",
  };

  return (
    <div style={containerStyle}>
      <Spinner animation="border" variant="primary" style={spinnerStyle} />
      <div style={loadingTextStyle}>Loading...</div>
      <style>
        {`
          @keyframes fade-in {
            0%, 100% {
              opacity: 0.3;
            }
            50% {
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
}

export default LoginCallback;

