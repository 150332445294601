import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { slideToggle } from './../../composables/slideToggle.js';
import {  logoutSuccess } from '../../redux/slices/authSlice.js';
import {  getUserSuccess } from '../../redux/slices/getUser.js';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faLock ,faVideo,faBarcode} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios'; 

function Header() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useSelector((state) => state.theme.theme);
	const user = useSelector((state) => state.getUser.user);
	const token = sessionStorage.getItem('token');

	const [notifications, setNotifications] = useState([]);
	const [unreadCount, setUnreadCount] = useState(0);

	// const notificationData = [{
	// 	"icon": "bi bi-bag text-theme",
	// 	"title": "NEW ORDER RECEIVED ($1,299)",
	// 	"time": "JUST NOW"
	// },{
	// 	"icon": "bi bi-person-circle text-theme",
	// 	"title": "3 NEW ACCOUNT CREATED",
	// 	"time": "2 MINUTES AGO"
	// },{
	// 	"icon": "bi bi-gear text-theme",
	// 	"title": "SETUP COMPLETED",
	// 	"time": "3 MINUTES AGO"
	// },{
	// 	"icon": "bi bi-grid text-theme",
	// 	"title": "WIDGET INSTALLATION DONE",
	// 	"time": "5 MINUTES AGO"
	// },{
	// 	"icon": "bi bi-credit-card text-theme",
	// 	"title": "PAYMENT METHOD ENABLED",
	// 	"time": "10 MINUTES AGO"
	// }];


	const markAsRead = async (notificationId) => {
		try {
		  await axios.patch(`${process.env.REACT_APP_BASE_URL}/notifications/${notificationId}/read`, {}, {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  });
		  // Fetch notifications and unread count again
		   fetchNotifications();
		  fetchUnreadCount();
		} catch (error) {
		  console.error('Error marking notification as read:', error);
		}
	  };


	  // Fetch notifications
	  const fetchNotifications = async () => {
		try {
		  const notificationData = await axios.get(`${process.env.REACT_APP_BASE_URL}/notifications`, {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  });

	
		  setNotifications(notificationData?.data);
		} catch (error) {
		  console.error('Error fetching notifications:', error);
		}
	  };
	
	  // Fetch unread notification count
	  const fetchUnreadCount = async () => {
		try {
		  const unreadCountData = await axios.get(`${process.env.REACT_APP_BASE_URL}/notifications/unread-count`, {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  });
		  console.log("unreadcount",unreadCountData)
		  setUnreadCount(unreadCountData?.data?.unreadCount || 0);
		} catch (error) {
		  console.error('Error fetching unread count:', error);
		}
	  };
	
	  useEffect(() => {
		fetchNotifications();
		fetchUnreadCount();
	  }, []);
	
	const toggleAppSidebarDesktop = () => {
		var elm = document.querySelector('.app');
		if (elm) {
			if (!(elm.classList.contains('app-with-top-nav') && elm.classList.contains('app-without-sidebar'))) {
				elm.classList.toggle('app-sidebar-collapsed');
				
				setTimeout(() => {
					window.dispatchEvent(new Event('resize'));
				}, 150);
			}
		}
	}
	
	const toggleAppSidebarMobile = () => {
		var elm = document.querySelector('.app');
		if (elm) {
			if (!(elm.classList.contains('app-with-top-nav') && elm.classList.contains('app-without-sidebar'))) {
				elm.classList.toggle('app-sidebar-mobile-toggled');
			} else {
				slideToggle(document.querySelector('.app-top-nav'));
			}
		}
	}

	const logout=()=>{
		
		
		sessionStorage.setItem('token', "");
		dispatch(logoutSuccess())
		dispatch(getUserSuccess({}))
	  navigate("/pages/login")

		
		


	}
	
	const toggleAppHeaderSearch = () => {
		var elm = document.querySelector('.app');
		elm.classList.toggle('app-header-menu-search-toggled');
	}

	return (
		<div id="header" className="app-header">
			<div className="desktop-toggler">
				<button type="button" className="menu-toggler" onClick={toggleAppSidebarDesktop}>
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
			</div>
			
			<div className="mobile-toggler">
				<button type="button" className="menu-toggler" onClick={toggleAppSidebarMobile}>
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
			</div>
			
			<div className="brand">
				<Link to={user?.Role?.name=="superAdmin"||user?.Role?.name=="subAdmin"?`/pages/${user?.UserPermissions[0]?.routeName}`:"/"} className="brand-logo">
					{/* <span className="brand-img">
						<span className="brand-img-text text-theme">H</span>
					</span> */}
					 <img  src={
              theme === 'dark'
                ? '/assets/img/dashboard/dark_logo_web.jpg'
                : '/assets/img/dashboard/light_logo_web.png'
            } style={{height:"56px",width:"95px"}} alt="Brand Logo" />
					{/* <span className="brand-text">HUD ADMIN</span> */}

				</Link>
			</div>
			
			<div className="menu">
				{/* <div className="menu-item dropdown">
					<a href="#/" onClick={toggleAppHeaderSearch} className="menu-link">
						<div className="menu-icon"><i className="bi bi-search nav-icon"></i></div>
					</a>
				</div> */}
				{/* <div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-icon"><i className="bi bi-grid-3x3-gap nav-icon"></i></div>
					</a>
					<div className="dropdown-menu fade dropdown-menu-end w-300px text-center p-0 mt-1">
						<div className="row row-grid gx-0">
							<div className="col-4">
								<Link to="/email/inbox" className="dropdown-item text-decoration-none p-3 bg-none">
									<div className="position-relative">
										<i className="bi bi-circle-fill position-absolute text-theme top-0 mt-n2 me-n2 fs-6px d-block text-center w-100"></i>
										<i className="bi bi-envelope h2 opacity-5 d-block my-1"></i>
									</div>
									<div className="fw-500 fs-10px text-inverse">INBOX</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/pos/customer-order" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-hdd-network h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">POS SYSTEM</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/calendar" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-calendar4 h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">CALENDAR</div>
								</Link>
							</div>
						</div>
						<div className="row row-grid gx-0">
							<div className="col-4">
								<Link to="/helper" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-terminal h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">HELPER</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/settings" className="dropdown-item text-decoration-none p-3 bg-none">
									<div className="position-relative">
										<i className="bi bi-circle-fill position-absolute text-theme top-0 mt-n2 me-n2 fs-6px d-block text-center w-100"></i>
										<i className="bi bi-sliders h2 opacity-5 d-block my-1"></i>
									</div>
									<div className="fw-500 fs-10px text-inverse">SETTINGS</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/widgets" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-collection-play h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">WIDGETS</div>
								</Link>
							</div>
						</div>
					</div>
				</div> */}
				{user?.Role?.name=="superAdmin"?<><div className="menu-item dropdown dropdown-mobile-full">
				<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
            <div className="menu-icon position-relative">
              <i className="bi bi-bell nav-icon"></i>
              {unreadCount > 0 && (
                <span
                  className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {unreadCount}
                </span>
              )}
            </div>
          </a>
					<div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
						<h6 className="dropdown-header fs-10px mb-1">NOTIFICATIONS</h6>
						<div className="dropdown-divider mt-1"></div>
						{notifications.length > 0 ? (notifications.map((notification, index) => ((
							<a href="#/" key={index} className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold" onClick={() => markAsRead(notification.id)}  style={{
								backgroundColor: notification.status === 'unread' ? '#d3d3d3' : 'transparent', // Grey for unread notifications
							  }}>
								<div className="fs-20px">
									<i className={notification.icon}></i>
								</div>
								<div className="flex-1 flex-wrap ps-3">
									<div className="mb-1 text-inverse">{notification.name}</div>
									<div className="small text-inverse text-opacity-50">requested you to become GQI Member</div>
								</div>
								<div className="ps-2 fs-16px">
									<i className="bi bi-chevron-right"></i>
								</div>
							</a>
						)))) : (
							<div className="dropdown-notification-item p-3 text-center">
								No record found
							</div>
						)}
						<hr className="mb-0 mt-2" />
						<div className="py-10px mb-n2 text-center">
							<a   onClick={(e) => {
              e.preventDefault(); // Prevent default anchor behavior
              navigate('/pages/get-all-notifications'); // Navigate to the GetAllNotification page
            }} className="text-decoration-none fw-bold">SEE ALL</a>
						</div>
					</div>
				</div></>:null}

				
				<div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						{/* <div className="menu-img online">
							<img src="/assets/img/user/profile.jpg" alt="Profile" height="60" />
						</div> */}
						<div className="menu-text d-sm-block d-none w-170px">{user?.username}</div>
					</a>
					<div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
						<Link to="/profile" className="dropdown-item d-flex align-items-center">PROFILE <i className="bi bi-person-circle ms-auto text-theme fs-16px my-n1"></i></Link>
						{/* <Link to="/email/inbox" className="dropdown-item d-flex align-items-center">INBOX <i className="bi bi-envelope ms-auto text-theme fs-16px my-n1"></i></Link>
						<Link to="/calendar" className="dropdown-item d-flex align-items-center">CALENDAR <i className="bi bi-calendar ms-auto text-theme fs-16px my-n1"></i></Link>
						<Link to="/settings" className="dropdown-item d-flex align-items-center">SETTINGS <i className="bi bi-gear ms-auto text-theme fs-16px my-n1"></i></Link> */}
<div className="dropdown-divider"></div>
<Link to="/pages/onboardingVideo" className="dropdown-item d-flex align-items-center">
    Onboarding Video <FontAwesomeIcon icon={faVideo} className="ms-auto text-theme fs-16px my-n1" />
  </Link>
<div className="dropdown-divider"></div>
{/* {user?.email!==`${process.env.REACT_APP_OKTA_EMAIL}`?
	<>
	<Link to="/pages/two-factor-authentification-details" className="dropdown-item d-flex align-items-center">
	Enable 2FA <FontAwesomeIcon icon={faBarcode} className="ms-auto text-theme fs-16px my-n1" />
	</Link>
	<div className="dropdown-divider"></div>
	</>
:null
} */}

<Link to="/pages/changePasswordProfile" className="dropdown-item d-flex align-items-center">
  Change Password <FontAwesomeIcon icon={faLock} className="ms-auto text-theme fs-16px my-n1" />
</Link>
						<div className="dropdown-divider"></div>
						<Link to="/pages/login" className="dropdown-item d-flex align-items-center" onClick={logout}>LOGOUT <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i></Link>
					</div>
				</div>
			</div>
			
			<form className="menu-search" method="POST" name="header_search_form">
				<div className="menu-search-container">
					<div className="menu-search-icon"><i className="bi bi-search"></i></div>
					<div className="menu-search-input">
						<input type="text" className="form-control form-control-lg" placeholder="Search menu..." />
					</div>
					<div className="menu-search-icon">
						<a href="#/" onClick={toggleAppHeaderSearch}><i className="bi bi-x-lg"></i></a>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Header;
