import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const token = sessionStorage.getItem('token'); // Adjust based on your token storage method

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
  return isAuthenticated && token? <Element {...rest} /> : <Navigate to="/pages/login" />;
};

export default PrivateRoute;