import { Card, CardBody } from './../../components/card/card.jsx';
import { Icon } from '@iconify/react';
import React, { useEffect, useContext, useState, } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  useDispatch } from 'react-redux';
import { signupStore } from '../../redux/slices/signupSlice.js';
import { useNavigate } from 'react-router-dom';


function PagesPricing() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const context = useContext(AppSettings);
	const [pricingPlans, setPricingPlans] = useState([]);
	

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');

		axios.get(`${process.env.REACT_APP_BASE_URL}/getSubscriptionsRegister`)
		.then(response => {
		  setPricingPlans(response.data);
		})
		.catch(error => {
		  console.error('Error fetching pricing data:', error);
		});

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};

		// eslint-disable-next-line
	}, []);

	const membershipSignup=async(id)=>{
		dispatch(signupStore({pricingId:id})); 
		navigate("/pages/register"); 
				
	}

	    // Function to convert description into a list of bullet points
		const parseDescriptionToList = (plan) => {
			// Assuming each bullet point is separated by a newline
		
			return plan?.summarizePoints?.map((point, index) => (
				<div key={index} className="d-flex align-items-center mb-1">
					<i className="fa fa-check fa-lg text-theme"></i>
					<div className="flex-1 ps-3">
						<span className="font-monospace small">{point}</span>
					</div>
				</div>
			));
		}
	
	

	return (
		<div>
			{/* <h1 className="page-header">Pricing Page <small>page header description goes here...</small></h1> */}
			<Card style={{ "marginLeft": "45px", "marginRight": "45px" , "marginTop": "45px" }}>
			<ToastContainer />
				<div className="row gx-0">
					<div className="col-xl-7 position-relative">
						<div className="p-5">
							<div className="display-5 fw-bold mb-3">Membership Plan</div>
							<div className="fs-13px mb-4 font-monospace">
								As a member you gain access to the leading business and market intelligence in Quantum Tech with access to our assets, reports and data insights across all types of applications and regions. We offer various subscription levels to make this accessible to the entire quantum community
							</div>
							
						</div>
					</div>
					<div className="col-xl-5 d-xl-block d-none">
						<div className="ms-n5 h-100 d-flex align-items-center justify-content-center p-3">
							<img src="/assets/img/pricing/img-1.svg" alt="" height="360" />
						</div>
					</div>
				</div>
			</Card>

			<div className="row gx-4 py-5" >

			{pricingPlans?.map((plan, index) => (

<div key={index} className="col-xl-3 col-md-6 py-3 py-xl-5">
<Card className="h-100" style={{ "marginLeft": "25px" }} >
	<CardBody className="p-30px d-flex flex-column">
		<div className="d-flex align-items-center">
			<div className="flex-1">
				<div className="h6 font-monospace">{plan.name}</div>
				{!(plan.name.toLowerCase().includes('free') || plan.name.toLowerCase().includes('gqi')) ? (
            <div className="display-6 fw-bold mb-0">
              ${Math.trunc(plan.price)}
              <small className="h6 text-body text-opacity-50">/month*</small>
            </div>
          ) : (
           null
          )}
				{/* <div className="display-6 fw-bold mb-0"><small className="h6 text-body text-opacity-50">/month*</small></div> */}
			</div>
			<div>
				<Icon className="iconify display-4 text-body text-opacity-50 rounded-3" icon="solar:usb-bold-duotone" />
			</div>
		</div>
		<hr className="my-20px" />
		<div className="mb-5 text-body text-opacity-75 flex-1">
		{parseDescriptionToList(plan)}
		
			
		
		</div>
		
		<div className="mx-n2">
		{plan.name.toLowerCase().includes('gqi') ? (
          <Link to="/pages/contact-us"><button type='button' className="btn btn-outline-default btn-lg w-100 font-monospace">
		  Contact Us <i className="fa fa-envelope"></i>
		</button></Link>  
          ) : (
            <button type='button' className="btn btn-outline-default btn-lg w-100 font-monospace" onClick={() => membershipSignup(plan.id)}>
              Get Started <i className="fa fa-arrow-right"></i>
            </button>
          )}
		</div>
	</CardBody>
</Card>
</div>

			))}
			
			
				{/* <div className="col-xl-3 col-md-6 py-3 py-xl-0">
					<Card className="h-100 border-theme">
						<CardBody className="p-30px h-100 d-flex flex-column">
							<div className="d-flex align-items-center">
								<div className="flex-1">
									<div className="h6 font-monospace">QCR Corporate Member</div>
									<div className="display-6 fw-bold mb-0 text-theme">$3000 <small className="h6 text-body text-opacity-50">/month*</small></div>
								</div>
								<div>
									<Icon className="iconify display-3 text-theme rounded-3" icon="solar:cup-first-bold-duotone" />
								</div>
							</div>
							<hr className="my-20px" />
							<div className="mb-5 text-body flex-1">
								<div className="d-flex align-items-center mb-1">
									<i className="fa fa-check text-theme fa-lg"></i>
									<div className="flex-1 ps-3"><span className="font-monospace small">The same premium QCR access than individual members but for your entire organization.</span> </div>
								</div>
								
							</div>
							<a href="#/" className="btn btn-theme btn-lg w-100 text-black font-monospace">Get Started <i className="fa fa-arrow-right"></i></a>
						</CardBody>
					</Card>
				</div> */}
				
			</div>
		</div>
	)
}

export default PagesPricing;